<template>
    <div>
        <InlineNotification class="p-0 m-0 mb-5 ml-4" DismissId="BidsMinFee">
            <template slot="body">
              <h3 class="text-lg leading-6 font-medium text-gray-900 flex">
                <SpeakerphoneIcon class="mr-2" color="pink"/> Gigs under minimum fee
              </h3>
              <p class="text-justify">The gigs below didn’t match your minimum fee, and we have let the venue know.</p>
              <p class="text-justify">You can view these gigs below. If there are any gigs you might fancy playing, give it a thumbs up and the venue will be told you are available.</p>
              <p class="text-justify text-red-500">REMEMBER - THE GIG IS ONLY BOOKED WHEN YOU GET A BOOKING CONFIRMATION. ALL CONFIRMED BOOKINGS WILL BE IN THE ‘BOOKED GIGS’ TAB (AND YOUR CALENDAR TOO).</p>
            </template>
        </InlineNotification>

        <div v-show="hasBids" class="max-w-6xl xl:flex xl:space-x-32 pb-4 pt-12 ml-4">
            <div class="w-80">
                <TRichSelect 
                    required 
                    id="profile-selection" 
                    name="profile-selection" class="trichselect"                    
                    :options="artistProfiles"  
                    value-attribute="name" 
                    text-attribute="name" 
                    placeholder="Filter by artist profile"
                    v-model="selectedProfile"
                />
            </div>

            <div class="items-center md:flex md:space-x-8 sm:mt-4">
                <span class="marker: text-xs font-medium text-gray-400 uppercase tracking-wider">Filter by date:</span>
                <div class="col-span-3 lg:col-span-1">
                    <input ref="start" placeholder="Start date" type="date" v-model="dateFilterStart" class="datepick h-full rounded-lg border-gray-300"/>
                </div>
                <span class="marker: text-xs font-medium text-gray-400 uppercase tracking-wider">to</span>
                <div class="col-span-3 lg:col-span-1">
                    <input ref="end" type="date" v-model="dateFilterEnd" class="datepick h-full rounded-lg border-gray-300"/>
                </div>
            </div>
        </div>

        <div v-show="hasBids" class="max-w-6xl xl:ml-96 ml-4">
          <div class="items-baseline md:flex md:space-x-8 sm:mt-4">
              <span class="marker: text-xs font-medium text-gray-400 uppercase tracking-wider xl:ml-16">Filter by gig fee:</span>
              <div class="relative w-40">
                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <span class="text-gray-500 sm:text-sm bg-gray-100 rounded-md py-1 px-2">£</span>
                </div>
                <input
                  v-model="priceFilterFrom" 
                  type="text"  
                  class="bg-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-12 p-2.5  dark:bg-none dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                  placeholder="0.00">
              </div>
              <span class="marker: text-xs font-medium text-gray-400 uppercase tracking-wider">to</span>
              <div class="relative mb-6 w-40">
                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <span class="text-gray-500 sm:text-sm bg-gray-100 rounded-md py-1 px-2">£</span>
                </div>
                <input
                  v-model="priceFilterTo" 
                  type="text"  
                  class="bg-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-12 p-2.5  dark:bg-none dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                  :placeholder="highestOffer">
              </div>
          </div>
        </div>

        <OfferByDate 
            ref="offers"
            noItemsText="No new gig offers just yet." 
            v-on:reloadData="loadData"  
            :showSelects="true"  
            :unfilteredBids="currentBids"
        />
    </div>
</template>

<script>
import client from "../../../utils/client"
import InlineNotification from '../../../components/notifications/inline.vue';
import { TRichSelect } from 'vue-tailwind/dist/components';
import { SpeakerphoneIcon } from "@vue-hero-icons/outline";
import NProgress from 'nprogress';
import OfferByDate from '../../../components/gigs/OfferByDate.vue';

export default {
    data()
    {
        return {
            currentBids: null,
            currentBidsCopy: null,
            artistProfiles: [],
            selectedProfile: null,
            profileSelected: 'All artist profiles',
            dateFilterStart: null,
            dateFilterEnd: null,
            date_filter_start: '2023-01-01T00:00:00.000000Z',
            date_filter_end: '3000-01-01T00:00:00.000000Z',
            priceFilterTo: null,
            priceFilterFrom: null,
            highestOffer: null,
            price_filter_from: 0,
            price_filter_to: 100000000,
        }
    },
    components: {
        InlineNotification,
        SpeakerphoneIcon,
        TRichSelect,
        OfferByDate
    },
    mounted() {
        this.loadData();
        this.loadArtists();
    },
    computed: {
        hasBids() {
            return this.currentBidsCopy !== null && this.currentBidsCopy.length
        }
    },
    watch: {
        selectedProfile(profile){
            this.profileSelected = profile;
            this.artistFilters();
        },
        dateFilterStart(val) {
            this.date_filter_start = !val ? '2023-01-01T00:00:00.000000Z' : val + 'T00:00:00.000000Z';
            this.artistFilters();
        },
        dateFilterEnd(val) {
            this.date_filter_end = !val ? '3000-01-01T00:00:00.000000Z' : val + 'T00:00:00.000000Z';
            this.artistFilters();
        },
        priceFilterFrom(val){
            this.price_filter_from = !val ? 0 : parseFloat(val);
            this.artistFilters();
        },
        priceFilterTo(val){
            this.price_filter_to = !val ? this.highestOffer : parseFloat(val);
            this.artistFilters();
        }
    },
    methods:{
        loadData()
        {
            NProgress.start();

            client.get('/artist/counter-offers')
                .then(response => {
                    this.currentBids = JSON.parse(JSON.stringify(response.data.data));
                    this.currentBidsCopy = this.currentBids;

                    let fees = [];

                    this.currentBids.map((b) =>{
                        fees.push(b.fee);
                    })
                    this.highestOffer = Math.max.apply(Math, fees) + '.00';
                })
                .catch((err) => {
                    console.log(err)
                }).finally(() => {
                    NProgress.done();
                })
        },
        loadArtists()
        {
            this.$store
                .dispatch('api/getArtists')
                .then((response) => {
                    if (response.length > 0){  
                        response.forEach(artist => {
                            let artistObj = {
                                name: artist.name
                            };
                            this.artistProfiles.push(artistObj);
                        });
                        this.artistProfiles = [{name: "All artist profiles", id: "all"}, ...this.artistProfiles];
                    }
                })
        },
        artistFilters(){
            if(this.profileSelected !== "All artist profiles") {
                this.currentBids = this.currentBidsCopy
                .filter((r) => r.artist.name == this.profileSelected && r.gig.start >= this.date_filter_start 
                                                && r.gig.end <= this.date_filter_end && r.fee >= this.price_filter_from
                                                && r.fee <= this.price_filter_to);
            } else {
                this.currentBids = this.currentBidsCopy
                .filter((r) => r.gig.start >= this.date_filter_start && r.gig.end <= this.date_filter_end && r.fee >= this.price_filter_from
                                                && r.fee <= this.price_filter_to);
            }   
        },
    }
}
</script>